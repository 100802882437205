import { inject } from "vue";
import { useQuasar } from 'quasar';

export function useMiniCalculatorHelperMixin() {
    var pushDataLayer = inject('pushDataLayer');
    const $q = useQuasar()

    const AddUtmQueryParams = () => {
        const urlParams = new URLSearchParams(window.location.search)
        const utmSource = urlParams.get('utm_source') ?? urlParams.get('source')
        const utmMedium = urlParams.get('utm_medium') ?? urlParams.get('medium')
        const gadSource = urlParams.get('gad_source');
        const fbclid = urlParams.get('fbclid');
        let propsLink = "";

        propsLink += utmSource !== null ? `&utm_source=${utmSource}` : ''
        propsLink += utmMedium !== null ? `&utm_medium=${utmMedium}` : ''
        propsLink += gadSource !== null ? `&gad_source=${gadSource}` : ''
        propsLink += fbclid !== null ? `&fbclid=${fbclid}` : ''

        return propsLink;
    }

    const getUtmParamsFromQuery = () => {
        const urlParams = new URLSearchParams(window.location.search);
        return {
            utm_source: urlParams.get('utm_source') ?? urlParams.get('source'),
            utm_medium: urlParams.get('utm_medium') ?? urlParams.get('medium')
        }
    }

    const pushDataLayerCalculationStep = (insurance, parameter1, parameter2, parameter3, eventCallback = null, isStickyCalculator = false) => {
        const device = $q.screen.width <= 1199 ? 'mobil' : 'desktop';
        const utmParams = getUtmParamsFromQuery();
        const event = isStickyCalculator ? 'calculation_step_sticky' : 'calculation_step';

        pushDataLayer(event, insurance, parameter1, parameter2, parameter3, device, utmParams, eventCallback);
    }

    const pushDataLayerAndRedirectBaleset = (balesetCalculator, balesetFormModel) => {
        const eventCallback = () => {
            window.location.href =
                balesetCalculator.calculateLink.internalLink +
                '?MINI_100=' + balesetFormModel.value.szerzodoNeme +
                '&MINI_101=' + balesetFormModel.value.felnottDarab +
                '&MINI_102=' + balesetFormModel.value.gyermekDarab
                + AddUtmQueryParams()
        }

        pushDataLayerCalculationStep(
            balesetCalculator.type + 'biztositas',
            balesetFormModel.value.szerzodoNemeOpciok.find(
                (element) => element.value == balesetFormModel.value.szerzodoNeme
            ).label,
            balesetFormModel.value.felnottDarab,
            balesetFormModel.value.gyermekDarab,
            eventCallback);
    }

    const pushDataLayerLakas = (lakasCalculator, lakasFormModel) => {
        pushDataLayerLakasInternal(lakasCalculator, lakasFormModel, null);
    }

    const pushDataLayerAndRedirectLakas = (lakasCalculator, lakasFormModel) => {
        const eventCallback = () => { 
            window.location.href =
            lakasCalculator.calculateLink.internalLink +
            '?MINI_103=' + lakasFormModel.value.biztositasTargya +
            '&MINI_104=' + lakasFormModel.value.ingatlanTipusa +
            '&MINI_105=' + lakasFormModel.value.iranyitoSzam
        }

        pushDataLayerLakasInternal(lakasCalculator, lakasFormModel, eventCallback);
    }

    const pushDataLayerLakasInternal = (lakasCalculator, lakasFormModel, eventCallback = null) => {
        const insurance = lakasCalculator.type.includes('lakaseloszto') ? 'lakasbiztositas' : lakasCalculator.type + 'biztositas';

        pushDataLayerCalculationStep(
            insurance,
            lakasFormModel.value.biztositastargyaOpciok.find(
                (element) =>
                    element.value == lakasFormModel.value.biztositasTargya
            ).label,
            lakasFormModel.value.ingatlantipusaOpciok.find(
                (element) => element.value == lakasFormModel.value.ingatlanTipusa
            ).label,
            lakasFormModel.value.iranyitoSzam,
            eventCallback);
    }

    const pushDataLayerAndRedirectUtas = (utasCalculator, utasFormModel, destinationOptions) => {
        const eventCallback = () => {
            window.location.href =
                utasCalculator.calculateLink.internalLink +
                '?MINI_114=' + utasFormModel.value.utazasOka +
                '&MINI_115=' + utasFormModel.value.utazasiJarmu +
                '&MINI_116=' + utasFormModel.value.uticel
                + AddUtmQueryParams()
        }

        pushDataLayerCalculationStep(
            utasCalculator.type + 'biztositas',
            utasFormModel.value.utazasOkaOpciok.find(
                (element) => element.value == utasFormModel.value.utazasOka
            ).label,
            utasFormModel.value.utazasiJarmuOpciok.find(
                (element) => element.value == utasFormModel.value.utazasiJarmu
            ).label,
            destinationOptions.value.find(
                (element) => element.value == utasFormModel.value.uticel
            ).label,
            eventCallback);
    }

    const pushDataLayerAndRedirectKotelezo = (kotelezoCalculator, kotelezoFormModel) => {
        const eventCallback = () => {
            window.location.href =
                kotelezoCalculator.calculateLink.internalLink +
                '?MINI_106=' + kotelezoFormModel.value.szerzodeskotesOka +
                '&MINI_107=' + `${kotelezoFormModel.value.frszNincs === true ? null : kotelezoFormModel.value.rendszam}` +
                '&MINI_117=' + kotelezoFormModel.value.frszNincs
                + AddUtmQueryParams()
        }

        pushDataLayerCalculationStep(
            kotelezoCalculator.type + 'biztositas',
            kotelezoFormModel.value.szerzodeskotesOkaOpciok.find(
                (element) =>
                    element.value == kotelezoFormModel.value.szerzodeskotesOka
            ).label,
            kotelezoFormModel.value.rendszam,
            kotelezoFormModel.value.frszNincs,
            eventCallback);
    }

    const pushDataLayerAndRedirectCasco = (cascoCalculator, cascoFormModel) => {
        const eventCallback = () => {
            window.location.href =
                cascoCalculator.calculateLink.internalLink +
                '?MINI_108=' + cascoFormModel.value.szerzodeskotesOka +
                '&MINI_107=' + `${cascoFormModel.value.frszNincs === true ? null : cascoFormModel.value.rendszam}` +
                '&MINI_109=' + cascoFormModel.value.felhasznalasmodja +
                '&MINI_117=' + cascoFormModel.value.frszNincs
                + AddUtmQueryParams()
        }

        pushDataLayerCalculationStep(
            cascoCalculator.type + 'biztositas',
            cascoFormModel.value.szerzodeskotesOkaOpciok.find(
                (element) =>
                    element.value == cascoFormModel.value.szerzodeskotesOka
            ).label,
            cascoFormModel.value.rendszam,
            cascoFormModel.value.felhasznalasmodjaOpciok.find(
                (element) =>
                    element.value == cascoFormModel.value.felhasznalasmodja
            ).label,
            eventCallback);
    }

    const pushDataLayerAndRedirectMiniCasco = (miniCascoCalculator, miniCascoFormModel) => {
        const eventCallback = () => {
            window.location.href =
                miniCascoCalculator.calculateLink.internalLink +
                '?MINI_110=' + miniCascoFormModel.value.szerzodoErdekeltseg +
                '&MINI_111=' + miniCascoFormModel.value.forgalmiNev +
                '&MINI_112=' + miniCascoFormModel.value.elozoKgfb +
                '&MINI_112_text=' + miniCascoFormModel.value.elozoKgfbOpciok.find(
                    (element) => element.value == miniCascoFormModel.value.elozoKgfb
                ).label
                + AddUtmQueryParams()
        }

        pushDataLayerCalculationStep(
            miniCascoCalculator.type + 'biztositas',
            miniCascoFormModel.value.szerzodoErdekeltsegOpciok.find(
                (element) =>
                    element.value == miniCascoFormModel.value.szerzodoErdekeltseg
            ).label,
            miniCascoFormModel.value.forgalmiNevOpciok.find(
                (element) => element.value == miniCascoFormModel.value.forgalmiNev
            ).label,
            miniCascoFormModel.value.elozoKgfbOpciok.find(
                (element) => element.value == miniCascoFormModel.value.elozoKgfb
            ).label,
            eventCallback);
    }

    const pushDataLayerAndRedirectLopasCasco = (lopasCascoCalculator, lopasCascoFormModel) => {
        const eventCallback = () => {
            window.location.href =
                lopasCascoCalculator.calculateLink.internalLink +
                '?MINI_110=' + lopasCascoFormModel.value.szerzodoErdekeltseg +
                '&MINI_111=' + lopasCascoFormModel.value.forgalmiNev +
                '&MINI_113=' + lopasCascoFormModel.value.gepjarmuFajtaja
                + AddUtmQueryParams()
        }

        pushDataLayerCalculationStep(
            lopasCascoCalculator.type + 'biztositas',
            lopasCascoFormModel.value.szerzodoErdekeltsegOpciok.find(
                (element) =>
                    element.value == lopasCascoFormModel.value.szerzodoErdekeltseg
            ).label,
            lopasCascoFormModel.value.forgalmiNevOpciok.find(
                (element) =>
                    element.value == lopasCascoFormModel.value.forgalmiNev
            ).label,
            lopasCascoFormModel.value.gepjarmuFajtajaOpciok.find(
                (element) =>
                    element.value == lopasCascoFormModel.value.gepjarmuFajtaja
            ).label,
            eventCallback);
    }

    return {
        pushDataLayerAndRedirectBaleset,
        pushDataLayerLakas,
        pushDataLayerAndRedirectLakas,
        pushDataLayerAndRedirectUtas,
        pushDataLayerAndRedirectKotelezo,
        pushDataLayerAndRedirectCasco,
        pushDataLayerAndRedirectMiniCasco,
        pushDataLayerAndRedirectLopasCasco,
        pushDataLayerCalculationStep
    }
}