<template>
  <div :class="isIframe ? '' : 'container-xxl'">
    <div
      class="minicalculator-holder"
      :style="
        isIframe ? 'margin-top: 0px !important; width: 100vw !important;' : ''
      "
    >
      <Title
        htag="h1"
        class="small"
      >
        <slot name="title" />
      </Title>
      <p class="description">
        <slot name="subtitle" />
      </p>
      <Row12>
        <Row12 v-if="discountLink !== null">
          <div
            v-if="
              (discountLink.internalLink || discountLink.externalLink) &&
                !showTooltipContent &&
                !discountLink.productPageAnchor
            "
            class="tag-long w-auto bg-purple"
            @click="clickInnerNavigableLink"
          >
            <div class="icon-holder bg-white">
              <slot name="tag-icon" />
            </div>
            <NavigableLink
              :link-object="discountLink"
              class="tag-text text-white text-bold t-normal"
            >
              <slot name="tag-text" />
            </NavigableLink>
            <PhIconManager
              ph-icon="ph-arrow-right"
              :size="16"
              color="white"
            />
          </div>
          <div 
            v-else-if="discountLink.productPageAnchor && !showTooltipContent" 
            class="tag-long w-auto bg-purple"
          >
            <div class="icon-holder bg-white">
              <slot name="tag-icon" />
            </div>
            <router-link
              class="tag-text text-white text-bold t-normal"
              :to="route.path+'#'+discountLink.productPageAnchor"
            >
              <slot name="tag-text" />
            </router-link>
          </div>
          <div v-else-if="showTooltipContent">
            <span class="cursor-pointer all-pointer-events">
              <div
                ref="tooltip"
                data-bs-toggle="tooltip"
                :data-bs-title="discountLink.tooltip"
                data-bs-container="body"
                data-bs-placement="bottom"
                data-bs-custom-class="tooltip"
                data-bs-html="true"
              >
                <div class="tag-long w-auto bg-purple">
                  <div class="icon-holder bg-white">
                    <slot name="tag-icon" />
                  </div>
                  <div class="tag-text text-white text-bold t-normal">
                    <slot name="tag-text" />
                  </div>
                  <ph-arrow-right
                    :size="16"
                    color="white"
                  />
                </div>
              </div>
            </span>
          </div>
        </Row12>
        <div class="input-holder">
          <slot name="input-holder" />
        </div>
        <Row12
          class="btn-row btn-calculate"
          :class="mobileRedirection ? 'desktop-tablet-submit-btn' : ''"
        >
          <q-btn
            :class="`btn btn-secondary btn-dense gtmProductPageCalculationStart${calculatorTitle}`"
            @click="pushDataLayerAndRedirectToCalculator"
          >
            {{ calculateLink.title }}
          </q-btn>
        </Row12>
        <Row12
          v-if="mobileRedirection"
          class="btn-row btn-calculate mobile-btn"
        >
          <q-btn
            class="btn btn-secondary btn-dense"
            @click="
              ;[
                isNew
                  ? submitFormToNew(inputRef, calculateLink, inputs)
                  : openMobileLink(),
              ]
            "
          >
            {{ calculateLink.title }}
          </q-btn>
        </Row12>
      </Row12>
    </div>
  </div>
</template>

<script setup>
import productViewHelper from '../../utils/products/productViewHelper.js'
import router from '../../router'
import { ref, onMounted, inject, onBeforeUnmount } from 'vue'
import { Tooltip } from 'bootstrap'
import { VueScreenSizeMixin } from 'vue-screen-size'
import { useCalculatorStore } from '../../stores/calculatorStore'
import { focusFirstValidation } from '@/utils/validations/validationFocus'
import { useRoute } from 'vue-router'
import { useMiniCalculatorHelperMixin } from "../../mixins/miniCalculatorHelperMixin"

const route = useRoute()
const calculatorStore = useCalculatorStore()

const { pushDataLayerCalculationStep } = useMiniCalculatorHelperMixin();

const props = defineProps({
  calculateLink: {
    type: Object,
    default: null,
  },
  discountLink: {
    type: Object,
    default: () => {
      return {
        externalLink: '',
        internalLink: '',
        tooltip: '',
      }
    },
  },
  inputs: {
    type: Object,
    default: null,
  },
  inputRef: {
    type: Object,
  },
  isIframe: {
    type: Boolean,
    default: false,
  },
  mobileLink: {
    type: String,
    default: '/',
  },
  mobileRedirection: {
    type: Boolean,
    default: false,
  },
  showTooltipContent: {
    type: Boolean,
    default: false,
  },
  calculatorTitle: {
    type: Object,
    default: null,
  },
  isNew: {
    type: Boolean,
    default: false,
  },
})

const tooltip = ref(null)

const insuranceHasMap = new Map([
  ['Kotelezo', 'kotelezobiztositas'],
  ['Lakaseloszto', 'lakasbiztositas'],
  ['Gtlakas', 'gtlakasbiztositas'],
  ['Mfo', 'mfolakasbiztositas'],
  ['Baleset', 'balesetbiztositas'],
  ['Casco', 'cascobiztositas'],
  ['Minicasco', 'minicascobiztositas'],
  ['Lopascasco', 'lopascascobiztositas'],
  ['Utas', 'utasbiztositas'],
])

onMounted(() => {
  if (tooltip.value) {
    new Tooltip(tooltip.value, {
      trigger: 'click',
      title: props.discountLink.tooltip,
    })
  }
})

onBeforeUnmount(() => {
  if (tooltip.value != null) {
    if (tooltip.value.getAttribute('aria-describedby')) {
      tooltip.value.click()
    }
  }
})

function clickInnerNavigableLink(element) {
  const currentTarget = element.currentTarget
  const navigableLink = currentTarget.getElementsByClassName(
    'tag-text text-white text-bold t-normal'
  )
  navigableLink[0].click()
}

const pushDataLayerAndRedirectToCalculator = () => {
  const eventCallback = () => {
    if (props.isNew) {
      submitFormToNew(props.inputRef, props.calculateLink, props.inputs)
    } else {
      productViewHelper.submitForm(props.inputRef, props.calculateLink, props.inputs, props.isIframe)
    }
  }

  pushDataLayerCalculationStep(
    insuranceHasMap.get(props.calculatorTitle),
    props.inputs.value1.text === ''
      ? props.inputs.value1.value
      : props.inputs.value1.text,
    props.inputs.value2.text === ''
      ? props.inputs.value2.value
      : props.inputs.value2.text,
    props.inputs.value3.text === ''
      ? props.inputs.value3.value
      : props.inputs.value3.text,
    eventCallback
  )
}

async function openMobileLink() {
  if (props.mobileLink) {
    if (!props.isIframe) {
      window.location.replace(props.mobileLink)
    } else {
      if (props.mobileLink.includes('m.genertel')) {
        window.open(props.mobileLink, '_blank')
      } else {
        productViewHelper.submitForm(
          props.inputRef,
          props.calculateLink,
          props.inputs,
          props.isIframe
        )
      }
    }
  }
}

function submitFormToNew(inputRef, calculateLink, inputs) {
  let urlParams = new URLSearchParams(window.location.search)
  let couponId = urlParams.get('couponId')

  if (!props.isIframe) {
    inputRef.validate().then(
      (success) => {
        if (success) {
          calculatorStore.sethousehold(
            inputs.value1,
            inputs.value2,
            inputs.value3.value,
            couponId
          )

          router.push({ path: calculateLink.internalLink })
        } else {
          focusFirstValidation()
        }
      },
      (error) => {
        alert('Váratlan hiba történt!')
        throw new Error(error)
      }
    )
  } else {
    inputRef.validate().then(
      (success) => {
        if (success) {
          window.location.href =
            import.meta.env.VITE_RESP_BASEURL +
            calculateLink.internalLink +
            '?MINI_103=' +
            inputs.value1.value +
            '&MINI_104=' +
            inputs.value2.value +
            '&MINI_105=' +
            inputs.value3.value
        } else {
          focusFirstValidation()
        }
      },
      (error) => {
        alert('Váratlan hiba történt!')
        throw new Error(error)
      }
    )
  }
}
</script>

<script>
export default {
  name: 'ProductMinicalCulator',
  mixins: [VueScreenSizeMixin],
}
</script>

<style lang="scss" scoped>
.container-xxl {
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.minicalculator-holder) {
  width: 33.75rem;
  margin-top: 5rem;

  background: linear-gradient(121.27deg, #fafbfc 31.25%, #faf7f4 100%);
  border-radius: 0.75rem;
  padding: 2.5rem;
  color: $dark;
  box-shadow: $box-shadow-md;
  .input-holder {
    margin-bottom: 1rem;
    .q-field--with-bottom .q-field__bottom {
      min-height: 1rem !important;
    }
  }
  & > h1.small {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin-bottom: $half;
  }
  & > p.description {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: $double;
  }
  .tag-long {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: $small 0.75rem $small $small;
    border-radius: 0.75rem;
    max-width: fit-content;
    margin-bottom: $double;
    cursor: pointer;

    .icon-holder {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: $normal;
    }
    .tag-text {
      margin-right: $normal;
    }

    &:hover {
      background: #811d8a !important;
    }
  }

  .btn-row {
    .btn-dense {
      padding: 0.75rem 1.25rem;
      box-shadow: 0rem 0.625rem 0.9375rem rgba(78, 149, 251, 0.1);
      border-radius: 0.75rem;
      font-size: $normal;
      line-height: $line-height-base;
      margin-right: 1.5rem;
      color: white;
      text-transform: none;
      font-weight: $font-weight-medium;
      &::before {
        box-shadow: none;
      }
    }
    a.text-secondary {
      font-size: $normal;
      line-height: $line-height-base;
      font-weight: $font-weight-medium;
    }
    a:hover {
      color: #3e77c9 !important;
    }
    a.q-btn:hover {
      color: white !important;
    }
  }
  .mobile-btn {
    display: none;
  }
}
.cursor-pointer.all-pointer-events {
  width: 100% !important;
  justify-content: start;
  height: auto !important;
  margin-left: 0;
}

@media screen and (max-width: $breakpoint-md-max) {
  :deep(.minicalculator-holder) {
    width: 100% !important;
    margin-top: 0 !important;
    margin-bottom: 0;
    border-radius: 0 !important;
    box-shadow: none !important;
    .mobile-btn {
      display: none;
    }
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .icon-holder {
    height: 1rem !important;
    width: 1rem !important;
    margin-right: 0.5rem !important;
  }
  .tag-text {
    margin-right: 0.5rem !important;
  }
  .minicalculator-holder {
    padding: 1.5rem 1rem;
    .description {
      margin-bottom: 2rem !important;
    }
    & > h1.small {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 0.25rem;
    }
    & > p.description {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 1.5rem;
    }
    .tag-long {
      margin-bottom: 1.5rem;
      .tag-text {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1.25rem;
      }
    }
    .btn-row {
      .btn-dense {
        width: 100%;
      }
    }

    
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .minicalculator-holder {
    .description {
      margin-bottom: 1.5rem !important;
    }
    .tag-long {
      .icon-holder {
        width: 1rem;
        height: 1rem;
        margin-right: $half;
      }
      .tag-text {
        margin-right: $half;
      }
    }
    .desktop-tablet-submit-btn {
      display: none;
    }
    .mobile-btn {
      display: block;
    }
  }
}
</style>
