export default {
  install: (app, options) => {
    // app.config.globalProperties.$pushDataLayer= (event, insurance, paramater_1, paramater_2, paramater_3,paramater_4, device) => {

    function pushDataLayer(event, insurance, parameter1, parameter2, parameter3, device, utmParams, eventCallback = null) {
      let dataLayerObject = { 
        event: event,
        insurance: insurance,
        parameter_1: parameter1,
        paramater_2: parameter2,
        parameter_3: parameter3,
        device: device,
        utm_uniqueEventId: 37
      }

      if (eventCallback) {
        dataLayerObject.eventCallback = eventCallback
      }

      if (utmParams != null) {
        for (let i in utmParams) {
          if (utmParams[i]) {
            dataLayerObject[i] = utmParams[i]
          }
        }
      }

      dataLayer.push(dataLayerObject);
    }

    function pushDataLayerNext(width, calculator, blockName, userType, step, utmParams) {      
      const device = width > 1199 ? "desktop" : "mobil";

      let dataLayerObject = {
        event: "calculation_step",
        flow: "biztositas",
        calculator: calculator,
        block: blockName,
        device: device,
        user_type: userType,
        step: step
      }

      if (utmParams != null) {
        for (let i in utmParams) {
          dataLayerObject[i] = utmParams[i]
        }
      }

      window.dataLayer?.push(dataLayerObject);
    }

    function pushDataLayerMarketingStatement(width, calculator, blockName, userType) {            
      const device = width > 991 ? "desktop" : "mobil";

      dataLayer.push({
        event: "marketing_consent",
        calculator: calculator,
        block: blockName,
        device: device,
        user_type: userType
      });
    }

    function pushDataLayerCoupon(width, event, insurance, coupon, userType, errorMessage) {
      const device = width > 991 ? "desktop" : "mobil";

      if (event == "coupon_redemption_success") {
        dataLayer.push({
          event: event,
          insurance: insurance,
          coupon: coupon,
          device: device,
          user_type: userType
        });        
      } else {
        // coupon_redemption_error
        dataLayer.push({
          event: event,
          insurance: insurance,
          coupon: coupon,
          error_message: errorMessage,
          device: device,
          user_type: userType
        });
      }
    }

    app.provide('pushDataLayer', pushDataLayer)
    app.provide('pushDataLayerNext', pushDataLayerNext)
    app.provide('pushDataLayerMarketingStatement', pushDataLayerMarketingStatement)
    app.provide('pushDataLayerCoupon', pushDataLayerCoupon)
  },
}