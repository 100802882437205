<template>
  <div class="backtotop-holder-product">
    <div class="container">
      <q-btn class="btn-backtotop-product d-lg-none">
        <div class="btn-text">
          <slot name="mobile-text" />
        </div>
        <q-menu
          ref="bottomModal"
          class="calculator-modal p-0"
          cover
          anchor="bottom left"
        >
          <div class="close-button-holder">
            <q-btn @click="closeModal">
              <PhIconManager
                ph-icon="ph-x"
                :size="20"
                color="#828BA8"
              />
            </q-btn>
          </div>
          <slot
            class="d-flex d-md-none"
            name="bottom-modal"
          />
          <slot
            class="d-none d-md-flex"
            name="bottom-modal-tablet"
          />
        </q-menu>
        <PhIconManager ph-icon="ph-arrow-up" />
      </q-btn>
      <q-form ref="inputRef">
        <div class="form-holder d-none d-lg-flex">
          <div class="row">
            <Row444 class="productform-holder">
              <template #first>
                <slot name="first" />
              </template>
              <template #second>
                <slot name="second" />
              </template>
              <template #third>
                <slot name="third" />
              </template>
            </Row444>
            <div class="btn-holder">
              <q-btn
                :class="`btn btn-secondary btn-dense gtmProductPageStickyCalculationStart${calculatorTitle}`"
                label=""
                @click="clickBackToTopProductCalculate"
              >
                {{ calculateLink.title }}
              </q-btn>
            </div>
          </div>
          <slot name="checkbox" />
        </div>
      </q-form>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, watch } from 'vue'
import productViewHelper from '../../utils/products/productViewHelper.js'
import { VueScreenSizeMixin } from 'vue-screen-size'
import { useCalculatorStore } from '../../stores/calculatorStore'
import router from '../../router'
import { useMiniCalculatorHelperMixin } from "../../mixins/miniCalculatorHelperMixin"

const calculatorStore = useCalculatorStore()
const { pushDataLayerCalculationStep } = useMiniCalculatorHelperMixin();

const props = defineProps({
  inputRef: {
    type: Object,
    default: null,
  },
  calculateLink: {
    type: Object,
    default: null,
  },
  inputs: {
    type: Object,
    default: null,
  },
  calculatorTitle: {
    type: Object,
    default: null,
  },
  isModalOpen: {
    type: Boolean,
    default: false,
  },
  isNew: {
    type: Boolean,
    default: false,
  },
})

const insuranceHasMap = new Map([
  ['Kotelezo', 'kotelezobiztositas'],
  ['Lakaseloszto', 'lakasbiztositas'],
  ['Gtlakas', 'gtlakasbiztositas'],
  ['Mfo', 'mfolakasbiztositas'],
  ['Baleset', 'balesetbiztositas'],
  ['Casco', 'cascobiztositas'],
  ['Minicasco', 'minicascobiztositas'],
  ['Lopascasco', 'lopascascobiztositas'],
  ['Utas', 'utasbiztositas'],
])

const inputRef = ref({})
const bottomModal = ref(null)

function closeModal() {
  bottomModal.value.hide()
}

async function clickBackToTopProductCalculate() {
  const success = await productViewHelper.validateForm(inputRef.value)
  
  if (success) {
    const eventCallback = () => {
      if(props.isNew) {
        submitFormToNew(inputRef.value, props.calculateLink, props.inputs)
      } else {
        productViewHelper.submitForm(inputRef.value, props.calculateLink, props.inputs, false)
      }
    }

    pushDataLayerCalculationStep(
      insuranceHasMap.get(props.calculatorTitle),
      props.inputs.value1.text === ''
        ? props.inputs.value1.value
        : props.inputs.value1.text,
      props.inputs.value2.text === ''
        ? props.inputs.value2.value
        : props.inputs.value2.text,
      props.inputs.value3.text === ''
        ? props.inputs.value3.value
        : props.inputs.value3.text,
      eventCallback,
      true
    )    
  }
}

function submitFormToNew(inputRef, calculateLink, inputs) {
  inputRef.validate().then(
    (success) => {
      if (success) {
        calculatorStore.sethousehold(
          inputs.value1,
          inputs.value2,
          inputs.value3.value
        )
        router.push({ path: calculateLink.internalLink })
      } else {
        focusFirstValidation()
      }
    },
    (error) => {
      alert('Váratlan hiba történt!')
      throw new Error(error)
    }
  )
}

watch(
  () => props.isModalOpen,
  (value, prevValue) => {
    bottomModal.value.hide()
  }
)
</script>

<script>
export default {
  name: 'BacktotopProduct',
  mixins: [VueScreenSizeMixin],
}
</script>
<style lang="scss">
.calculator-modal {
  left: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  min-height: fit-content;
  max-height: calc(100vh - 5.5rem) !important;
  .close-button-holder {
    width: 100%;
    text-align: right;
    position: absolute;
    margin-top: 1rem;
  }
  .container-xxl {
    padding: 0;
  }
  .description-section {
    padding: 0;
    background: #f7f7fb;
    padding-top: 1rem;
    .q-field:not(.q-field--error) {
      .q-field__bottom {
        min-height: 0.25rem !important;
        max-height: 0.25rem;
        padding: 0 !important;
      }
    }
    &.vhk {
      .form-title {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.75rem;
        text-align: center;
        color: #1d2c5b;
        width: 100%;
        margin-bottom: 1.5rem;
      }
      .btn-block {
        padding: 0.5rem 1rem;
        height: 2.5rem;
        box-shadow: 0rem 0.625rem 0.9375rem rgba(78, 149, 251, 0.1);
        border-radius: 0.5rem;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.5rem;
      }
      .phone-input.row .text-dark {
        margin-bottom: 0;
        font-size: 0.75rem;
        line-height: 1.25rem;
      }
    }
    .desc-subtitle {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: center;
      color: #1d2c5b;
      margin-top: 0.25rem;
    }
  }
}
.backtotop-holder-product {
  z-index: 999;
  position: fixed;
  margin: 0 !important;
  background: #ffffff;
  box-shadow: 0rem -0.625rem 0.9375rem rgba(0, 0, 0, 0.1),
    0rem -0.25rem 0.375rem rgba(0, 0, 0, 0.05);
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  .container {
    padding: 0.75rem 0 !important;
  }

  @media screen and (max-width: $breakpoint-md-max) {
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem;
    }
    .q-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0.375rem 1rem;
      min-height: 2.5rem;
      border: 0.125rem solid $secondary;
      border-radius: $half;
      background: transparent;
      color: $secondary;
      width: auto;
      text-transform: none;
      font-size: 0.875rem;
      line-height: 1rem;
      font-weight: 500;

      .q-btn__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .btn-text {
          flex: 1;
        }
        svg {
          max-width: 1.25rem !important;
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.form-holder {
  flex-direction: column;
  .row {
    width: unset;
    .btn-holder {
      width: unset;
      max-width: unset;
      padding-left: 1rem;
    }
  }
  .row.checkbox {
    height: 1.25rem;
    margin-top: 0.75rem;
  }
}
.productform-holder {
  flex: 1;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  .col {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .q-field--with-bottom .q-field__bottom {
    min-height: 0 !important;
    padding: 0 0 0 !important;
  }
}

.btn-holder {
  padding-left: 2.5rem;
}

footer.productfooter {
  margin-bottom: 6.5rem;
}
footer.vehiclefooter {
  margin-bottom: 6.5rem;
}
@media screen and (max-width: $breakpoint-xl-max) {
  .backtotop-holder-product {
    .container {
      max-width: 100% !important;
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
  }
}
@media screen and (max-width: $breakpoint-md-max) {
  footer.productfooter {
    margin-bottom: 3.5rem;
  }
  footer.vehiclefooter {
    margin-bottom: 3.5rem;
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .q-checkbox{
    .t-sm{
      margin-bottom: 0 !important;
    } 
  }
}
@media screen and (max-width: $breakpoint-xs-max) {
  .q-checkbox{
    margin-top: .5rem !important;
  }
}

@media screen and (min-width: $breakpoint-lg) {
  .backtotop-holder-product {
    .container {
      padding: 0.75rem 1.5rem !important;
    }

    .productform-holder {
      .q-field__control {
        min-height: 3rem;
        height: 3rem;
        .q-field__append {
          height: 3rem;
        }
        .q-field__label {
          top: 0.875rem;
        }
      }
    }
    .q-btn {
      padding: 0.875rem 1.5rem;
      .q-btn__content {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }
}
</style>
